import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
	faCubes, faTasks, faCloudUploadAlt, faCodeBranch, faLaptopCode, faPalette, faCode, faUpload,
	faTriangleExclamation, faMobile, faGears
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faCubes, faTasks, faCloudUploadAlt, faCodeBranch, faLaptopCode, faPalette, faCode, faUpload,
	faTriangleExclamation, faMobile, faGears
);

dom.watch();
