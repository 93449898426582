import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.scss';

// run global init scripts
import './init';
import gsap from 'gsap';
import { SHOW_BLOG } from './content/constants';

// setup scroll-to-top global behavior
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
}

// setup per-route lazy chunks
const Home = React.lazy(() => import('./routes/Home'));
const About = React.lazy(() => import('./routes/About'));
const Projects = React.lazy(() => import('./routes/Projects'));
const ProjectPage = React.lazy(() => import('./routes/ProjectPage'));
const Services = React.lazy(() => import('./routes/Services'));
const ServicePage = React.lazy(() => import('./routes/ServicePage'));
const Process = React.lazy(() => import('./routes/Process'));
const Blog = React.lazy(() => import('./routes/Blog'));
const BlogPage = React.lazy(() => import('./routes/BlogPage'));
const Contact = React.lazy(() => import('./routes/Contact'));
const Terms = React.lazy(() => import('./routes/Terms'));

const Fallback = () => {
	return <div className="suspense-preloader" />;
}

const App = () => {
	// when the app has fully loaded, animate out the preloader
	useEffect(() => {
		window.addEventListener('load', () => {
			const preloader = document.querySelector('#preloader') as HTMLElement;
			const preloaderIcon = document.querySelector('#preloader-icon svg') as HTMLElement;

			const hidePreloader = () => {
				if (preloader) preloader.style.display = 'none';
			};

			gsap.to(preloaderIcon, { duration: 0.5, ease: 'quint.in', opacity: 0, scale: 0 });
			gsap.to(preloader,     { duration: 0.5, ease: 'quint.out', delay: 0.5, background: 'transparent', onComplete: hidePreloader });
		});
	});

	return (
		<React.Suspense fallback={<Fallback />}>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/"               element={<Home />        } />
					<Route path="/about"          element={<About />       } />
					<Route path="/projects"       element={<Projects />    } />
					<Route path="/projects/:id/*" element={<ProjectPage /> } />
					<Route path="/services"       element={<Services />    } />
					<Route path="/services/:id/*" element={<ServicePage /> } />
					<Route path="/process"        element={<Process />     } />
					{ SHOW_BLOG ?
						<>
							<Route path="/blog"           element={<Blog />        } />
							<Route path="/blog/:id/*"     element={<BlogPage />    } />
						</>
					: null }
					<Route path="/contact/*"      element={<Contact />     } />
					<Route path="/terms"          element={<Terms />       } />
				</Routes>
			</BrowserRouter>
		</React.Suspense>
	);
}

export default App;
